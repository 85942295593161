/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal } from "../../../../../bit_components/ui/modal";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Primarytext, SheduleModalDivSection } from "./index.styles";
import {
    Switch,
    Form,
    TimePicker,
    Tooltip,
} from "antd";
import { Dropdown } from "../../../../../bit_components/common/dropdown";
import {
    scheduleFrequency,
    weekSchedule,
    SchedulePickerFormat,
    ScheduleDefaultTime,
    jsonData,
} from "../../../../constants/constants";
import moment from "moment-timezone";
import "antd/dist/antd.css";

import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { MyWorkSelector, clearUpdateMyWorkIteFormState } from "../../../../../redux/reducers/workItems/myWorkReducer";

import { AppService } from "../../../../services/ui/appService";
import {
    cameFromOrganisation,
    convert_DateWithTimezone_To_UTC,
    getOrganizationDateAndTime,
    translateOptions,
} from "../../../../../utility/appUtil";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { Messages } from "../../../../constants/messages";
import { clearSavedFormValues } from "../../../../../redux/reducers/common/commonReducer";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import './style.less'
import { fetchMyWorkItemList, updateMyWorkItem } from "../../../../../redux/actions/workItems/myWorkAction";


export const ScheduleModal = (props: any) => {
    const { isModalVisible, setModalVisible, editable, viewDataValues } = props;
    const { selectedOrganisation } = useSelector(appSelector);
    const { updateMyWorkItemFormState } = useSelector(MyWorkSelector);
    const { appUser } = useSelector(userStateSelector);
    const [toggle, settoggle] = useState<boolean>(false);
    const [isScheduleUpdate, setIsScheduleUpdate] = useState<boolean>(false);
    const [ferquencyValue, setFrequencyValue] = useState<string>();
    const [deliveryDate, setDeliveryDate] = useState<any>();
    const [customTime, setCustomTime] = useState<any>(null);
    const [disable, setDisable] = useState<boolean>(true);
    const [form] = Form.useForm();
    const [updatedParams, setUpdatedParams] = useState<any>();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    let localeInfo: any = secureLocalStorage.getItem('locale')
    moment.tz.setDefault(localeInfo.timezone);

    let time = "";
    const getScheduleTime = () => {
        let date = viewDataValues?.time.split(":");
        time = date[0] + ":" + date[1];
        return time;
    };

    if (viewDataValues?.time) {
        time = getScheduleTime();
    }

    let yearandmonth, day;
    if (viewDataValues?.frequency === "0") {
        yearandmonth = moment().utc().format("YYYY-MM");
        day = parseInt(moment().format("DD"));
    } else if (viewDataValues?.frequency === "1") {
        let date = moment()
            .day(viewDataValues?.deliveryDate - 1)
            .toString();
        yearandmonth = moment(date).format("YYYY-MM");
        day = parseInt(moment(date).format("DD"));
    } else if (viewDataValues?.frequency === "2") {
        yearandmonth = moment().utc().format("YYYY-MM");
        day = viewDataValues?.deliveryDate;
    }

    let dateAndTime;
    if (viewDataValues !== null) {
        dateAndTime = getOrganizationDateAndTime(
            moment.utc(viewDataValues?.nextRun).format()
        );
    } else {
        dateAndTime = getOrganizationDateAndTime(
            moment
                .utc(
                    yearandmonth +
                    "-" +
                    day +
                    " " +
                    viewDataValues?.time
                )
                .format()
        );
    }
    const timecal = viewDataValues?.time
        ? moment(
            moment(dateAndTime).format(SchedulePickerFormat.TIME),
            SchedulePickerFormat.TIME
        )
        : null;
    let DeliveryDate;
    if (viewDataValues?.frequency === "2") {
        DeliveryDate = parseInt(moment(dateAndTime).format("D"));
    } else if (viewDataValues?.frequency === "1") {
        DeliveryDate = moment(dateAndTime).day() + 1;
    } else {
        DeliveryDate = null;
    }

    let initialParams = {
        frequency: viewDataValues?.frequency,
        deliveryDate: DeliveryDate,
        time: viewDataValues?.time
            ? moment(timecal).format("HH:mm")
            : null,
        isActive: viewDataValues?.isActive,
    };

    useEffect(() => {
        setUpdatedParams(initialParams);
    }, [viewDataValues]);

    const onSavingSchedule = (e: any) => {
        if (e.target.name === "frequency" && e.target.value === "0") {
            setUpdatedParams({
                ...updatedParams,
                deliveryDate: null,
                [e.target.name]: e.target.value,
            });
        } else {
            setUpdatedParams({ ...updatedParams, [e.target.name]: e.target.value });
        }
    };

    useEffect(() => {
        ((!ferquencyValue && !customTime && !deliveryDate) ||
            (!ferquencyValue || (customTime && customTime !== 'Invalid date') || !deliveryDate)
            &&
            (
                initialParams?.deliveryDate === updatedParams?.deliveryDate &&
                initialParams?.frequency === updatedParams?.frequency &&
                initialParams?.time === updatedParams?.time &&
                initialParams?.isActive === updatedParams?.isActive
            )
            || (ferquencyValue !== "0" && deliveryDate === null)
        ) ? setDisable(true) : setDisable(false)
    }, [viewDataValues, updatedParams]);

    const handleCancel = () => {
        setModalVisible(false);
        form.resetFields();
        dispatch(clearSavedFormValues());
        settoggle(true);
        setCustomTime(null);
        setFrequencyValue(null);
        setDeliveryDate(null);
    };
    
    const getOrgId = () => {
        let orgId = appUser?.organization.id as string;
        if (cameFromOrganisation()) {
            orgId = selectedOrganisation?.id;
        }
        return orgId;
    };

    useEffect(() => {
        if (updateMyWorkItemFormState?.isSuccess && isScheduleUpdate) {
            dispatch(clearUpdateMyWorkIteFormState());
            dispatch(
                fetchMyWorkItemList({ organisationId: getOrgId(), userId: appUser?.id })
            );
            setIsScheduleUpdate(false)
            AppService.showToast(t(Messages.SCHEDULE_WORKITEM_UPDATED));
        }
    }, [updateMyWorkItemFormState?.isSuccess]);

    const getScheduleUtcTime = () => {
        let dateAndTime;
        if (ferquencyValue === "0") {
            dateAndTime = convert_DateWithTimezone_To_UTC(
                moment().format("YYYY-MM-DD") + " " + customTime
            );
        } else if (ferquencyValue === "1") {
            let date = moment(
                moment()
                    .day(deliveryDate - 1)
                    .toString()
            ).format("YYYY-MM-DD");
            dateAndTime = convert_DateWithTimezone_To_UTC(date + " " + customTime);
        } else {
            let yearandmonth = moment().format("YYYY-MM");
            dateAndTime = convert_DateWithTimezone_To_UTC(
                yearandmonth + "-" + deliveryDate + " " + customTime
            );
        }
        return dateAndTime;
    };

    const onScheduleMyWorkItem = () => {
        let UTC = getScheduleUtcTime();
        let deliveryDate;
        if (ferquencyValue === "2") {
            deliveryDate = parseInt(moment.utc(UTC).format("DD"));
        } else if (ferquencyValue === "1") {
            deliveryDate = moment.utc(UTC).day() + 1;
        }

        let scheduleMyWorkItemData: any = {
            frequency: ferquencyValue,
            deliveryDate: deliveryDate,
            time: moment.utc(UTC).format("HH:mm"),
            createdBy: appUser?.id,
            updatedBy: appUser?.id,
            isActive: toggle
        };
        if (editable) {
            let parameter = {
                id: viewDataValues?.id,
                body: scheduleMyWorkItemData
            }
            dispatch(updateMyWorkItem(parameter));
            setIsScheduleUpdate(true)
        }

        setModalVisible(false);
    };

    const handleToggle = (value: any) => {
        settoggle(value);
        onSavingSchedule({
            target: {
                name: "isActive",
                value: value,
            },
        });
    };
    const onSelectFrequency = (value: any) => {
        setFrequencyValue(value);
        setDeliveryDate(null);
        onSavingSchedule({
            target: {
                name: "frequency",
                value: value,
            },
        });
        form.setFieldsValue({
            DeliveryDate: "",
        });
    };



    const onDeliveryDate = (value: any) => {
        setDeliveryDate(value);
        onSavingSchedule({
            target: {
                name: "deliveryDate",
                value: value,
            },
        });
    };

    const handleTimepicker = (evt: any, starttime: any) => {
        starttime?.length ? setCustomTime(starttime) : setCustomTime(null);
        onSavingSchedule({
            target: {
                name: "time",
                value: starttime,
            },
        });
    };

    const DropDownValue = () => {
        if (ferquencyValue === "1") {
            return weekSchedule;
        } else if (ferquencyValue === "2") {
            let list = [];
            for (let i = 1; i <= 31; i++) {
                list.push({
                    text: i,
                    value: i,
                });
            }
            return list;
        } else return [];
    };

    useEffect(() => {
        if (editable === true) {
            let yearandmonth, day;
            if (viewDataValues?.frequency === "0") {
                yearandmonth = moment().utc().format("YYYY-MM");
                day = parseInt(moment().format("DD"));
            } else if (viewDataValues?.frequency === "1") {
                let date = moment()
                    .day(viewDataValues?.deliveryDate - 1)
                    .toString();
                yearandmonth = moment(date).format("YYYY-MM");
                day = parseInt(moment(date).format("DD"));
            } else {
                yearandmonth = moment().utc().format("YYYY-MM");
                day = viewDataValues?.deliveryDate;
            }
            let dateAndTime;
            if (viewDataValues !== null) {
                dateAndTime = getOrganizationDateAndTime(
                    moment.utc(viewDataValues?.nextRun).format()
                );
            } else {
                dateAndTime = getOrganizationDateAndTime(
                    moment
                        .utc(
                            yearandmonth +
                            "-" +
                            day +
                            " " +
                            viewDataValues?.time
                        )
                        .format()
                );
            }

            const timecal = viewDataValues?.time
                ? moment(moment(dateAndTime).format())
                : null;
            let DeliveryDate;
            if (viewDataValues?.frequency === "2") {
                DeliveryDate = moment(dateAndTime).format("D");
            } else if (viewDataValues?.frequency === "1") {
                DeliveryDate = moment(dateAndTime).day() + 1;
            } else {
                DeliveryDate = null;
            }
            form.setFieldsValue({
                Enable: viewDataValues?.isActive,
                Frequency: viewDataValues?.frequency,
                ApproxTime: timecal,
                DeliveryDate: DeliveryDate,
            });
            (viewDataValues?.isActive == undefined || null) ? settoggle(true) : settoggle(viewDataValues?.isActive);
            setCustomTime(moment(timecal).format("HH:mm"));
            setFrequencyValue(viewDataValues?.frequency);

            setDeliveryDate(
                viewDataValues?.frequency !== "0"
                    ? DeliveryDate
                    : null
            );
        } else {
            settoggle(true)
            form.resetFields();
        }
    }, [editable, isModalVisible, viewDataValues])

    return (
        <>
            <Modal
                handleCancel={handleCancel}
                handleOk={onScheduleMyWorkItem}
                okText={t(jsonData.Save)}
                primaryButtonDisabled={disable}
                cancelText="Cancel"
                isVisible={isModalVisible}
            >
                <SheduleModalDivSection>
                    <Form form={form} onChange={onSavingSchedule}>
                        <Primarytext>
                            <div className="myinsighttableColNameStyle">
                                <Tooltip
                                    overlayClassName="dashBoardTooltip"
                                    placement="bottom"
                                    title={`Schedule${viewDataValues?.name}`}
                                >
                                    {t(jsonData.Schedule)} {viewDataValues?.name}
                                </Tooltip>
                            </div>
                        </Primarytext>
                        <Form.Item
                            label={t(jsonData.Enabled)}
                            name="Enable"
                            className="switchScheduleForm"
                        >
                            <Switch
                                className={`${toggle ? "scheduleToggleCheck" : "scheduleToggleOff"
                                    } ScheduleToggleSwitch `}
                                checked={toggle}
                                onChange={handleToggle}
                            ></Switch>
                        </Form.Item>
                        <Form.Item
                            className="schedule_dropDowns"
                            label={t(jsonData.WorkItemName)}
                            name="WorkItemName"
                            initialValue={null}
                            rules={[
                                { required: true, message: t(jsonData.ApproxTimeRequired) },
                            ]}
                        >
                            <span className="myworkitem-table-col-name schedule-workitem-name">
                                <Tooltip
                                    overlayClassName="dashBoardTooltip"
                                    placement="bottom"
                                    title={`${viewDataValues?.workItemDescription}`}
                                >
                                    {viewDataValues?.workItemDescription}
                                </Tooltip>
                            </span>

                        </Form.Item>

                        <Form.Item
                            className="schedule_dropDown"
                            label={t(jsonData.Frequency)}
                            name="Frequency"
                            initialValue={null}
                            rules={[
                                { required: true, message: t(jsonData.FrequencyIsRequired) },
                            ]}
                        >
                            <Dropdown
                                tabIndex={0}
                                className="frequencyDropdown"
                                optionValue={translateOptions(scheduleFrequency, t, "text")}
                                onChange={onSelectFrequency}
                            ></Dropdown>
                        </Form.Item>
                        {ferquencyValue !== "0" &&
                            ferquencyValue !== undefined &&
                            ferquencyValue !== null ? (
                            <Form.Item
                                className="schedule_dropDown"
                                label={t(jsonData.DeliveryDate)}
                                name="DeliveryDate"
                                initialValue={null}
                                rules={[
                                    { required: true, message: t(jsonData.DeliveryDateRequired) },
                                ]}
                            >
                                <Dropdown
                                    tabIndex={0}
                                    value={deliveryDate}
                                    optionValue={DropDownValue()}
                                    onChange={onDeliveryDate}
                                ></Dropdown>
                            </Form.Item>
                        ) : null}

                        <Form.Item
                            className="schedule_dropDown"
                            label={t(jsonData.ApproxTime)}
                            name="ApproxTime"
                            initialValue={null}
                            rules={[
                                { required: true, message: t(jsonData.ApproxTimeRequired) },
                            ]}
                        >
                            <TimePicker
                                placeholder={ScheduleDefaultTime.START}
                                onChange={handleTimepicker}
                                format={SchedulePickerFormat.TIME}
                                className="ScheduletimePickerStyl"
                                value={
                                    customTime
                                        ? moment(customTime, SchedulePickerFormat.TIME)
                                        : null
                                }
                            />
                        </Form.Item>
                    </Form>
                </SheduleModalDivSection>
            </Modal>
        </>
    );
};
