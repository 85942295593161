/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Menu,Tooltip, Dropdown, Space } from 'antd'
import { Breadcrumbs } from '../../../../../bit_components/common/breadcrumbs'
import { Button } from '../../../../../bit_components/common/button'
import { cameFromOrganisation, getHeight, getOrganizationDateAndTime, urlModification } from '../../../../../utility/appUtil'
import { AppRoutes } from '../../../../constants/enums'
import { SideBarItems } from '../../../../constants/sideBarConstants'
import { AntdInfinityTable } from '../../../stateless/common/antdTable/antdInfininityTable'
import Loader from '../../../stateless/common/spinner'
import { HeaderLaytout, HeaderDiv, ContentLayout } from '../../../stateless/common/styledComponents/common.styles'
import { Title } from './index.styles'
import './style.less'
import WarnModal from '../../../stateless/common/warnModal'
import { InsightDeleteWarnModal, InsightRenameWarnModal, MyInsightActons, Pages, PickerFormat, jsonData } from "../../../../constants/constants";
import { Custominput } from "../../../../../bit_components/common/custominput";
import { commonSelector } from "../../../../../redux/reducers/common/commonReducer";
import { useSelector } from "react-redux";
import {
    saveDirtyFormValues,
    saveFormValues,
} from "../../../../../redux/actions/common/commonAction";
import { useDispatch } from "react-redux";
import { SavedFormValue } from "../../../../models/common/savedFormValue";
import { ScheduleModal } from "./sheduleModal";
import { useHistory } from "react-router-dom";
import { clearDeleteReportFormstate, clearMyReportDataFormSate, clearMyReports, clearUpdateReportFormstate, insightsSelector, setViewDataValues, showInitialMyReport } from "../../../../../redux/reducers/insights/insightsReducer";
import { deleteMyReport, fetchMyReport, fetchMyReportById, renameMyReport } from "../../../../../redux/actions/insights/insightsAction";
import { AppService } from "../../../../services/ui/appService";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { Messages } from "../../../../constants/messages";
import { fetchTransformUserList } from "../../../../../redux/actions/user/userListAction";
import { localeInfo } from "../../../../models/user/localization";
import { Link } from "react-router-dom";
import { MenuSchedule } from "./menu";
import moment from "moment-timezone";
import { useTranslation } from 'react-i18next';
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import secureLocalStorage from "react-secure-storage";

export const MyInsights = (props: any) => {
    const [warnCancelBtn, setWarnCancelBtn] = useState("");
    const [warnConfirmBtn, setWarnConfirmBtn] = useState("");
    const [warnTitle, setWarnTitle] = useState("");
    const [warnContent, setWarnContent] = useState<any>("");
    const [showWarn, setShowWarn] = useState(false);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [action, setActon] = useState<string>("");
    const [insightName, setInsightName] = useState("");
    const { savedFormValues, isDirty } = useSelector(commonSelector)
    const { selectedOrganisation } = useSelector(appSelector);
    const { appUser } = useSelector(userStateSelector)
    const { myReports, myReportformState, viewDataValues, deleteReportFormstate, updateReportFormstate,myReportDataFormState ,scheduleFormState,UpdateScheduleFormState} = useSelector(insightsSelector)
    const [disable, setDisable] = useState<boolean>(false)
    const [form] = Form.useForm()
    const [target, setTarget] = useState();
    const [editable, setEditable] = useState<Boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const dispatch = useDispatch();
    const history = useHistory()
    const [scrollHeight, setScrollHeight] = useState(200);
    const [screenHeight, setScreenHeight] = useState(document.body.clientHeight)
    const [visibleState,getVisibleState] = useState<boolean>()
    const [menuClicked,setMenuClicked] = useState<boolean>(false)
    const [breadCrumbClick, setBredCrumClick] = useState<Boolean>(false)
    const { t } = useTranslation()

    let localeInfo: any = secureLocalStorage.getItem('locale')
    moment.tz.setDefault(localeInfo.timezone)

    useEffect(() => {
        const node = document.body
        const resizeObserver = new ResizeObserver((entries) => {
            setScreenHeight(node?.clientHeight)
        });
        resizeObserver.observe(node);
    }, [])

    useEffect(() => {
        if (!showLoading) {
            const compContentHeight = getHeight('.ant-layout-content')
            const bottomGap = compContentHeight - (screenHeight * 0.8)
            const tableHeight = getHeight('.ant-table-body') - bottomGap
            const scrollH = parseInt(tableHeight.toFixed(0));
            scrollH && !isNaN(scrollH) && setScrollHeight(scrollH)
        }
    }, [myReports, showLoading, screenHeight])

    useEffect(()=>{

        const node = document.querySelector('.ant-table-body')
        if(visibleState){
            if(node){
                !node.classList.contains('hide-tableScroll') && node.classList.add('hide-tableScroll')
            }
        }
        else{
            if(node){
                node.classList.contains('hide-tableScroll') &&  node.classList.remove('hide-tableScroll')
            }

        }
    },[visibleState])


    useEffect(() => {
        dispatch(onPage({ onComponent: Pages.JUNTO_PORTAL }))
        dispatch(sidebarNavigate(SideBarItems.INSIGHTS))
        dispatch((fetchTransformUserList(getOrgId())))
        dispatch(fetchMyReport({ organisationId: getOrgId(), userId: appUser.id }))
        return () => {
            //clear my report
            clearMyReports()
        }
    }, [])

    useEffect(() => {
        myReportformState?.loading || deleteReportFormstate?.loading || updateReportFormstate?.loading ||scheduleFormState?.loading  || UpdateScheduleFormState?.loading || deleteReportFormstate.loading ?
            setShowLoading(true)
            :
            setShowLoading(false)
    }, [myReportformState, deleteReportFormstate, updateReportFormstate,scheduleFormState,UpdateScheduleFormState,deleteReportFormstate])

    const getOrgId = () => {
        let orgId = appUser?.organization.id as string
        if (cameFromOrganisation()) {
            orgId = selectedOrganisation?.id
        }
        return orgId
    }

    const routes = [
        {
            href: urlModification(AppRoutes.INSIGHTS),
            text: (t(jsonData.Insights)),
        }
    ]

    const onNameChange = (e: any) => {
        setInsightName(e.target.value)
        setTarget(e.target)
    }

    const onViewClick = (data: any) => {
        setMenuClicked(true)
        dispatch(setViewDataValues(data))
        history.push(urlModification(AppRoutes.MY_SAVED_INSIGHT.split(':')[0] + data.id), { Viewable: true });
    }

    const onEditClick = (data:any) => {
        setMenuClicked(true)
        const array = AppRoutes.MY_SAVED_INSIGHT_EDIT.split(':id')
        const arr1 = array[0]
        const arr2 = array[1]
        dispatch(setViewDataValues(data))
        history.push(urlModification(arr1 + data?.id + arr2), { editable: true })
        dispatch(showInitialMyReport(true))
    }

    useEffect(() => {
        if (!isDirty || insightName === "") {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [isDirty, insightName])

    useEffect(() => {
        if (savedFormValues) {
            if (target) {
                dispatch(
                    saveDirtyFormValues({
                        target: target,
                        savedFormValues: savedFormValues,
                    })
                );
            }
        }
    }, [target, insightName])

    const onInsightDelete = (data: any) => {
        setMenuClicked(true)
        getVisibleState(false)
        setActon(MyInsightActons.DELETE);
        setShowWarn(true);
        setWarnCancelBtn(t(InsightDeleteWarnModal.CANCEL_BTN_TEXT));
        setWarnConfirmBtn(t(InsightDeleteWarnModal.CONFIRM_BTN_TEXT));
        setWarnTitle(`${t(InsightDeleteWarnModal.TITLE)} ${data?.name}`);
        setWarnContent(t(InsightDeleteWarnModal.CONTENT));
        dispatch(setViewDataValues(data))
    };

    const onInsightRename = (data: any) => {
        setMenuClicked(true)
        getVisibleState(false)
        dispatch(setViewDataValues(data))
        let initialValues: SavedFormValue[] = [
            {
                field: "name",
                value: data?.name,
            },
        ]
        dispatch(saveFormValues(initialValues))
        setActon(MyInsightActons.RENAME)
        setShowWarn(true);
        setWarnCancelBtn(t(InsightRenameWarnModal.CANCEL_BTN_TEXT));
        setWarnConfirmBtn(t(InsightRenameWarnModal.CONFIRM_BTN_TEXT));
        setWarnTitle(`${t(InsightRenameWarnModal.TITLE)} ${data?.name}`);
        setWarnContent(
            <Form form={form} initialValues={{ name: data?.name }} >
                <Form.Item name="name" >
                    <Custominput
                        customLabelClass="labelName formControl mt-20"
                        name="name"
                        labelName="name"
                        placeholder="Name"
                        rules={[
                            {
                                required: true,
                                message: t(jsonData.PleaseMentionName)
                            },
                            {
                                max: 100,
                                message: t(jsonData.LimitExceeded100characters)
                            }
                        ]}
                        onChange={onNameChange}
                    />
                </Form.Item>
            </Form>
        )
        form.setFieldsValue({ name: data?.name })
    };

    const onScheduleClick = (data: any) => {
        setMenuClicked(true)
        getVisibleState(false)
        setShowScheduleModal(true)
        dispatch(setViewDataValues(data))
        if(data.reportSchedulingId !== null){
            setEditable(true)
        }else{
            setEditable(false)
        }
    };

    const onWarnModalCallback = () => {
        setMenuClicked(false)
        setShowWarn(false);
        switch (action) {

            case MyInsightActons.DELETE:
                dispatch(deleteMyReport(viewDataValues?.id))
                break
            case MyInsightActons.RENAME:
                dispatch(renameMyReport({ ...viewDataValues, name: insightName }))
                break
        }

    };

    useEffect(() => {
        if (deleteReportFormstate.isSuccess) {
            setMenuClicked(false)
            AppService.showToast(t(Messages.DELETED));
            dispatch(clearDeleteReportFormstate());
            dispatch(fetchMyReport({ organisationId: getOrgId(), userId: appUser.id }))
        }

    }, [deleteReportFormstate.isSuccess]);

    useEffect(() => {
        if (updateReportFormstate.isSuccess) {
            setMenuClicked(false)
            AppService.showToast(t(Messages.UPDATED));
            dispatch(clearUpdateReportFormstate());
            dispatch(fetchMyReport({ organisationId: getOrgId(), userId: appUser.id }))
        }
    }, [updateReportFormstate.isSuccess])

    const handleRunNow = (id: any) => {
        setMenuClicked(true)
        getVisibleState(false)
        dispatch(fetchMyReportById({ id, sendEmail: true }))
    }

    useEffect(() => {
        if (myReportDataFormState.isSuccess) {
            setMenuClicked(false)
            AppService.showToast(t(Messages.RUN_NOW));
            dispatch(clearMyReportDataFormSate());
            dispatch(fetchMyReport({ organisationId: getOrgId(), userId: appUser.id }))
        }
    }, [myReportDataFormState])

    const handelDropDown = (e: any, data: any) => {

        const dropdown: any = document.querySelector(`.customDropDownTable${data?.id}`)
        if (e?.keyCode === 13) {
            dropdown.click();
            setTimeout(() => {
            const menuList: any = document.querySelector(`.menuDropdownCustomList${data?.id}`)
                menuList?.focus()
            }, 100)
        }
    }
  
    const handleFunction = (e: any, data: any) => {
        const dropdown: any = document.querySelector(`.customDropDownSpace${data?.id}`);
        if (!e?.shiftKey && e?.keyCode === 9) {
            dropdown.click();
            dropdown.focus();
        }
    }
  
    const handleCloseDropdown = (itemID: any) => {
        const dropdown: any = document.querySelector(`.customDropDownSpace${itemID}`);
        dropdown.click();
        setTimeout(() => {
            dropdown.focus();
        }, 100);
    }

    // closing the dropdown if shift + tab is pressed
    const handleShiftTab = (e: any, dataID: any) => {
        if (e?.keyCode === 9 && e?.shiftKey) {
            handleCloseDropdown(dataID);
        }
    }

    // closing the dropdown if Escape key is pressed
    const handleEscapePress = (e: any, dataID: any) => {
        if (e?.keyCode === 27) {
            handleCloseDropdown(dataID);
        }
    }

    const menuFunction =(data:any)=>{
        const menu = (
            <Menu tabIndex={0} className={`filter subMenuFix menuDropdown menuDropdownCustomList${data?.id}`} onKeyDown={(e:any) => handleEscapePress(e, data?.id)}>
                <Menu.Item tabIndex={0} className='filterChild subMenuChild' key='1' onClick={() => onViewClick(data)} onKeyDown={(e:any) => handleShiftTab(e, data?.id)}>
                    {t(jsonData.View)}
                </Menu.Item>
                <Menu.Item tabIndex={0} className='filterChild subMenuChild' key='2' onClick={() => onEditClick(data)}>
                    {t(jsonData.Edit)}
                </Menu.Item>
                <Menu.Item tabIndex={0} className='filterChild subMenuChild' key='3' onClick={() => onScheduleClick(data)}>
                    {t(jsonData.Schedule)}
                </Menu.Item>
                <Menu.Item tabIndex={0} className= {(data?.reportSchedulingId === null) ? 'filterChild subMenuChild runNowDisabled':'filterChild subMenuChild'} key='4' disabled={data?.reportSchedulingId === null} onClick={() => { handleRunNow(data?.id) }}>
                    {t(jsonData.RunNow)}
                </Menu.Item>
                <Menu.Item tabIndex={0} className='filterChild subMenuChild' key='5' onClick={() => onInsightRename(data)}>
                    {t(jsonData.Rename)}
                </Menu.Item>
                <Menu.Item onKeyDown={(e) =>handleFunction(e,data)} tabIndex={0} className='filterChild subMenuChild' key='6' onClick={() => onInsightDelete(data)}>
                    {t(jsonData.Delete)}
                </Menu.Item>
            </Menu>
        )

        return menu;
    }

    const getScheduleDate = (data: any) => {

        let utcYearAndMonth, utcDay, ScheduledOrgDateAndTme

        if (data?.reportScheduling?.frequency === "0") {
            utcYearAndMonth = moment().utc().format('YYYY-MM')
            utcDay = parseInt(moment().format('DD'))
        } else if (data?.reportScheduling?.frequency === "1") {
            let date = moment().day(data?.reportScheduling?.deliveryDate - 1).toString()
            utcYearAndMonth = moment(date).format('YYYY-MM')
            utcDay = parseInt(moment(date).format('DD'))
        } else {
            utcYearAndMonth = moment().utc().format('YYYY-MM')
            utcDay = data?.reportScheduling?.deliveryDate
        }
        // let orgDateAndTime = getOrganizationDateAndTime(moment.utc(utcYearAndMonth + "-" + utcDay + " " + data?.reportScheduling?.time).format())
        let orgDateAndTime
        if(data.reportScheduling!== null) {
            orgDateAndTime = getOrganizationDateAndTime(moment.utc(data?.reportScheduling?.nextRun).format())
        } else {
            orgDateAndTime = getOrganizationDateAndTime(moment.utc(utcYearAndMonth + "-" + utcDay + " " + data?.reportScheduling?.time).format())
        }
        
        let orgTime = moment.utc(orgDateAndTime).format("hh:mm a")
        let orgDay = moment.utc(orgDateAndTime).format('dddd')
        let orgDate = moment.utc(orgDateAndTime).format("Do")

        if (data?.reportScheduling?.frequency === "0") {
            ScheduledOrgDateAndTme = "Daily at" + " " + orgTime
        } else if (data?.reportScheduling?.frequency === "1") {
            ScheduledOrgDateAndTme = "Weekly," + " " + orgDay + " at " + orgTime
        } else if (data?.reportScheduling?.frequency === "2") {
            ScheduledOrgDateAndTme = "Monthly on the " + orgDate + " at " + orgTime
        } else {
            ScheduledOrgDateAndTme = null
        }
        return ScheduledOrgDateAndTme
    }



    const myInsightColumns = () => {

        let columns = [
            {
                title: <div className="myinsighttableColNameStyle"><Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title="Name">{t(jsonData.Name)}</Tooltip></div>,
                dataIndex: 'name',
                sorter: (a: any, b: any) => a.name.localeCompare(b.name),
                key: "name",
                ellipsis: true,
                render: (name: string, data: any) => {
                    return (
                        <span tabIndex={0} onKeyPress={() => onViewClick(data)} className="nameLink MyInsight-reportName" onClick={() => onViewClick(data)}>
                            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={name}>
                                {name}
                            </Tooltip>
                        </span>)
                }
            },
            {
                title: <div className="myinsighttableColNameStyle"><Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title="Scheduled">{t(jsonData.Scheduled)}</Tooltip></div>,
                key: "Scheduled",
                ellipsis: true,
                render: (data: any) => {
                    let scheduleDate = getScheduleDate(data)
                    return (
                        <span className="MyInsight-reportName" >
                            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={data?.reportScheduling?.frequency === "0"? "Daily" : (data?.reportScheduling?.frequency === "1" ? "weekly" : (data?.reportScheduling?.frequency === "2" ? "monthly" : null))}>
                                {scheduleDate}
                            </Tooltip>
                        </span>)
                }
            },
            {
                title: <div className="myinsighttableColNameStyle"><Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title="Last Run Sucess">{t(jsonData.LastRunSuccess)}</Tooltip></div>,
                key: "last_run",
                ellipsis: true,
                render: (data: any) => {
                    return (
                        <span className="MyInsight-reportName" >
                            { data?.reportScheduling !== null && data?.reportScheduling?.lastRun !== null ? (data?.reportScheduling?.lastScheduleStatus ? (jsonData.Success) : (jsonData.failed)):null }
                        </span>)
                }
            },
            {
                title: <div className="myinsighttableColNameStyle"><Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title="Last Run Date">{t(jsonData.LastRunDate)}</Tooltip></div>,
                key: "Date",
                ellipsis: true,
                render: (data: any) => {
                    return (<span className="MyInsight-reportName" ><Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={data?.reportScheduling?.lastRun ? getOrganizationDateAndTime(data?.reportScheduling?.lastRun, localeInfo.momentFormat + " " + PickerFormat.TIME):''}>
                        {data?.reportScheduling?.lastRun ? getOrganizationDateAndTime(data?.reportScheduling?.lastRun, localeInfo.momentFormat + " " + PickerFormat.TIME):''}
                    </Tooltip>
                    </span>)
                }
            },
            {
                title: <div className="myinsighttableColNameStyle"><Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title="Additional features">{t(jsonData.Additionalfeatures)}</Tooltip></div>,
                key: "menu",
                ellipsis: true,
                render: (data: any) => {
                    return (
                        // <MenuSchedule menu={menu} data={data} getVisibleState={getVisibleState} menuClicked={menuClicked} />
                        <Dropdown overlay={menuFunction(data)} trigger={['click']} className={`customDropDownTable${data?.id}`}>
                            <a tabIndex={-1} onClick={(e) => e.preventDefault()}>
                                <Space tabIndex={0} onKeyUp={(e: any) => handelDropDown(e, data)} className={`customDropDownSpace${data?.id}`}>
                                    ...
                                </Space>
                            </a>
                        </Dropdown>
                    )
                }
            },

        ]

        return columns
    }

    const breadcrumbClick  = () => {
        setBredCrumClick(true)
      }

    const handleKeyDown = () => {
        history.push(urlModification(AppRoutes.INSIGHTS))
    }


    return (
        <>
            <WarnModal
                cancelButton={warnCancelBtn}
                confirmButton={warnConfirmBtn}
                primaryText={warnTitle}
                secondaryText={warnContent}
                isModalVisible={showWarn}
                disabled={action === MyInsightActons.DELETE ? false : disable}
                cancelCallback={() => {
                    setShowWarn(false);
                    setMenuClicked(false)
                }}
                confirmCallback={() => onWarnModalCallback()}
            />
            <ScheduleModal isModalVisible={showScheduleModal} setModalVisible={setShowScheduleModal} editable={editable} setMenuClicked={setMenuClicked} />
            <HeaderLaytout>
                <span onKeyPress={breadcrumbClick}>
                <Breadcrumbs breadcrumbs={routes}  breadCrumbClick={breadCrumbClick} setBredCrumClick={setBredCrumClick}/>
                </span>
                <HeaderDiv>
                    <Title>{t(jsonData.MyInsights)}</Title>
                    { !showLoading && myReports?.length === 0 ?
                    <div tabIndex={0}  onKeyPress={handleKeyDown}>

                        <Link to={urlModification(AppRoutes.INSIGHTS)}>
                            <Button type="actionButton">
                                {t(jsonData.Create)}
                            </Button>
                        </Link>
                    </div>
                        :
                        null}
                </HeaderDiv>
            </HeaderLaytout>
            <ContentLayout>
                <Loader loading={showLoading}>
                    <AntdInfinityTable
                        tableClassName='myInsights-list'
                        dataSource={myReports}
                        // onScroll={onScroll}
                        scroll={
                            {
                                y: scrollHeight,
                                scrollToFirstRowOnChange: true
                            }}
                        locale={{ emptyText: t(jsonData.NoDataFound) }}
                        columns={myInsightColumns()}
                        // onChange={(pagination, filters, sorter, extra) => {
                        //     filterSorting(pagination, filters, sorter, extra)
                        // }}
                        // onRow={onRow}
                        pagination={false}
                    // onFetch={loadMore}
                    // pageSize={DEVICE_SETTING_DEFAULT_LIMIT}
                    />
                </Loader>
            </ContentLayout>
        </>
    )
}