/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import axios from 'axios'
import { MainLayoutService } from "../../../../services/ui/mainLayoutService";
import { HeaderType } from "../../../../constants/enums";
import { useDispatch, useSelector } from "react-redux";
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { jsonData, Pages } from "../../../../constants/constants";
import { CompWrapper } from "../../../stateless/common/compWrapper/compWrapper";
import { setCancelToken } from "../../../../../redux/reducers/device/deviceReducer";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import Iframe from 'react-iframe'
import { IFrameContainer, SortingLayoutHeader } from "./index.styles";

export const SortingLayout = (props: any) => {
  const dispatch = useDispatch();
  const { selectedDevice } = useSelector(appSelector);
  const { t } = useTranslation();

  useEffect(() => {
    let token = axios.CancelToken.source()
    dispatch(setCancelToken({cancelToken:token,cancel:false}))
    dispatch(onPage({ onComponent: Pages.DEVICES }));
    MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
    dispatch(sidebarNavigate(SideBarItems.SORTING_LAYOUT));
    return () => {
      if (token) {
        dispatch(setCancelToken({cancelToken:null,cancel:true}))
        token.cancel("Operation canceled due to new request.")
      }
    }
  }, [])

  return (
    <>
      <Row className="device-health-header pl-vw">
        <Col tabIndex={0} >
          <SortingLayoutHeader>{t(jsonData.SortingLayout)}</SortingLayoutHeader>
        </Col>
      </Row>
      
      <CompWrapper otherClasses="deviceComponents">
        <IFrameContainer>
          <Iframe url={`${process.env.REACT_APP_SELF_URL}/Secure/SortingLayout.aspx?deviceids=${selectedDevice.lcDeviceId}`}
            position="absolute"
            width="90%"
            id="sortingLayout"
            className="sorting-layout"
            height="82%"
          />
        </IFrameContainer>
      </CompWrapper>
    </>
  )
};
