import styled from "styled-components";

export const IFrameContainer = styled.div`
    margin-left: 3.5%;
    padding-bottom: 20px;
`;

export const SortingLayoutHeader = styled.div`
    font-family: ${(props) => props.theme.fonts[0]};
    font-size: ${(props) => props.theme.fontSizes.h1};
    font-style: normal;
    font-weight: 500;
    line-height: 54px;
    display: flex;
    align-items: center;
    padding-left: 23px;
`