export enum AppRoutes {
  
  // Public
  VALIDATE_EMAIL = '/',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  CREATE_PASSWORD = '/create-password',

  // User
  CHANGE_PASSWORD = '/user/change-password',
  USER_DASHBOARD = '/user/dashboard',

  // Organisation
  ORGANISATION_OVERVIEW = '/organisation/overview',
  ORGANISATION_LIST = '/organisation/list',
  ORGANISATION_DETAILS = '/organisation/organisationDetails',
  ADD_ORGANISATION = '/organisation/add',
  ORGANISATION_USERS = '/organisation/users',
  ORGANISATION_ADD_NEW_USER = '/organisation/user/new',
  ORGANISATION_USER_PROFILE = '/organisation/user/profile',

  // Settings
  GENERAL_SETTINGS = '/settings/general',

  ORGANISATION_LOCATIONS = '/settings/locations',

  CREATE_DEVICE = '/settings/devices/add',
  DEVICE_INFO = '/settings/devices/info',
  DEVICE_SETTINGS = '/settings/devices',

  USER_LIST = '/settings/user/list',
  ADD_NEW_USER = '/settings/user/new',
  USER_PROFILE = '/settings/user/profile',

  ROLES_LIST = '/settings/roles/list',
  ROLES_DETAILS = '/settings/roles/details',
  ADD_ROLES = '/settings/roles/add',

  AUDIT_LOGS = '/settings/auditLogs',

  ALERT_CONFIG_SETTINGS = '/settings/alert',
  LOGOUT = '/settings/logout',

  // AccountSettings
  ACCOUNT_SETTINGS_ACCOUNT = '/accountSettings/profile',
  ACCOUNT_SETTINGS_GENERAL = '/accountSettings/general',
  ACCOUNT_SETTINGS_NOTIFICATIONS = '/accountSettings/notifications',

  // Devices
  DEVICE_LIST = '/device/devices',
  DEVICE_DETAILS = '/device/devices/:deviceId',
  DEVICE_HEALTH = '/devices/health',
  DEVICE_COMPONENTS = '/devices/components',
  DEVICE_CONFIGURATION = '/devices/configuration',
  SORTING_LAYOUT = '/devices/sortingLayout',

  // Event Logs
  EVENT_LOGS = '/logs/eventLogs',
  EVENT_LOGS_OUTPUT = '/eventLogs/:eventId',

  // Alerts
  ALERT_CENTRE = '/alert/centre',

  // Insights
  INSIGHTS = '/insights',
  MY_INSIGHTS = '/insights/saved',
  MY_SAVED_INSIGHT = '/insights/saved/:id',
  MY_SAVED_INSIGHT_EDIT = '/insights/saved/:id/edit',

  // Work Items
  WORK_ITEMS = '/workItems',
  MY_WORK_ITEMS = '/workItems/schedule',
}

export enum LeftMenuType {
  NONE = "@@none",
  BIBLIOTHECA_NORMAL = "@@bibliotheca/NORMAL",
  BIBLIOTHECA_RED = "@@bibliotheca/RED",
  JUNTO_NORMAL = "@@junto/NORMAL",
  JUNTO_BLACK = "@@junto/BLACK",
}

export enum HeaderType {
  NONE = "@@none",
  BIBLIOTHECA_PORTAL = "@@bibliotheca/PORTAL",
  JUNTO_PORTAL = "@@junto/PORTAL",
}

export enum UserRole {
  NONE = "@@userRole/NONE",
  PLATFORM_ADMIN = "Bibliotheca Admin",
  PROJECT_MANAGER = "Bibliotheca Project Manager",
  SUPPORT = "Bibliotheca Support User",
  SITE_ENGINEER = "Bibliotheca Site Engineer",
  LOCATION_ADMIN = "Location Admin",

  ORGANISATION_ADMIN = "Organisation Admin",
  LIBRARY_BACK_OFFICE = "Back Office",
  LIBRARY_FRONT_DESK = "Front Office",
  LIBRARY_DIRECTOR_MANAGER = "Library Manager",
  BIBLIOTHECA_USER = "Bibliotheca User"
}

export enum DisplayViewType {
  CARD_VIEW = "0",
  LIST_VIEW = "1",
}

export enum APIResponseError {
  NOT_FOUND = "NotFoundError",
  USER_FORBIDDEN = "ForbiddenError",
  AUTH_FAILURE = "AuthFailureError",
  ALREADY_EXISTS = "AlreadyExistsError",
  INVALID_PARAMETER_EXCEPTION = "InvalidParameterException",
}

export enum Permissions {
USERS = "Users",
ORGANISATION = "Organisation Details",
ROLE = "Roles",
DEVICE = "Devices", 
SUBSCRIPTION = "Subscriptions",
LOCATIONS = "Locations",
MASTER_CONFIG = "Master Configuration",
PRODUCT_CONFIG = "Product Configuration",
DEVICE_CONFIG = "Device Configuration",
ALERTS = "Alerts",
}
