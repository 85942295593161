import { OrganisationList } from '../components/containers/organisation/list'
import { OrganisationOverview } from '../components/containers/organisation/overview'
import { OrganisationDetails } from '../components/containers/organisation/details'
import { BibliothecaUsers } from '../components/containers/organisation/bibliothecaUsers'
import { OrgLocation } from '../components/containers/organisation/organisationLocations/orgLocation'

import { UserDashboard } from '../components/containers/user/dashboard'
import { UserList } from '../components/containers/user/list'
import { ValidateEmail } from '../components/containers/user/auth/validateEmail'
import { Login } from '../components/containers/user/auth/login'
import { ForgotPwd } from '../components/containers/user/auth/forgotPwd'
import AddOrganization from '../components/containers/organisation/addOrganization'

import { ResetPwd } from '../components/containers/user/auth/resetPwd'
import { UserProfile } from '../components/containers/user/profile'
import { RolesList } from '../components/containers/roles/rolesList/rolesList'
import { RolesDetails } from '../components/containers/roles/rolesDetails/rolesDetails'
import { CreateRole } from '../components/containers/roles/createRole/createRole'
import { InviteUser } from '../components/containers/user/inviteUser'

import { AccountSettings } from '../components/containers/accountSettings/account/account'
import { GeneralSettings } from '../components/containers/accountSettings/general/generalSettings'
import { NotificationsSettings } from '../components/containers/accountSettings/notifications/notifications'
import { AlertConfigSettings } from '../components/containers/alertConfigSettings'
import { ChangePwd } from '../components/containers/user/changePwd'
import { DeviceList } from '../components/containers/device/list'
import { EventListContainer } from '../components/containers/eventLogs'
import { AuditListContainer } from '../components/containers/auditLog'
import { Settings } from '../components/containers/settings/generalSettings/generalSettings'
import AlertCentre from '../components/containers/alerts/AlertsCentreTable/AlertsCentre'
import { EventOutput } from '../components/containers/eventLogs/eventOutput'
import Insights from '../components/containers/insights'
import { NewUserFlow } from '../components/stateless/user/auth/newUserFlow'
import { DeviceHealth } from '../components/containers/device/deviceHealth'
import { DeviceComponents } from '../components/containers/device/deviceComponents'
import { AppRoutes } from '../constants/enums'
import { CreateDevice } from '../components/containers/deviceSettings/createDevice'
import { DeviceInfo } from '../components/containers/deviceSettings/deviceInfo'
import { DeviceSettings } from '../components/containers/deviceSettings'
import { MyInsights } from '../components/containers/insights/myInsights'
import { Logout } from '../components/containers/accountSettings/account/Logout'
import DeviceConfiguration from '../components/containers/device/deviceConfiguration'
import { WorkItems } from '../components/containers/workItems'
import { MyWorkitems } from '../components/containers/workItems/myWorkItems'
import { SortingLayout } from '../components/containers/device/sortingLayout'


export const PublicRoutes = [
  {
    path: AppRoutes.VALIDATE_EMAIL,
    component: ValidateEmail,
  },
  {
    path: AppRoutes.LOGIN,
    component: Login,
  },
  {
    path: AppRoutes.FORGOT_PASSWORD,
    component: ForgotPwd,
  },
  {
    path: AppRoutes.RESET_PASSWORD,
    component: ResetPwd,
  },
  {
    path: AppRoutes.CREATE_PASSWORD,
    component: NewUserFlow,
  },
]

export const PrivateRoutes = [
  {
    routePath: '/user/:path?',
    routes: [
      {
        path: AppRoutes.CHANGE_PASSWORD,
        component: ChangePwd,
      },
      {
        path: AppRoutes.USER_DASHBOARD,
        component: UserDashboard,
      },
    ],
  },
  {
    routePath: '/logs/:path?',
    routes: [
      {
        path: AppRoutes.EVENT_LOGS,
        component: EventListContainer,
      },
    ],
  },
  {
    routePath: '/organisation/:path?',
    routes: [
      {
        path: AppRoutes.ORGANISATION_OVERVIEW,
        component: OrganisationOverview,
      },
      {
        path: AppRoutes.ORGANISATION_LIST,
        component: OrganisationList,
      },
      {
        path: AppRoutes.ORGANISATION_DETAILS,
        component: OrganisationDetails,
      },
      {
        path: AppRoutes.ADD_ORGANISATION,
        component: AddOrganization,
      },
      {
        path: AppRoutes.ORGANISATION_USERS,
        component: BibliothecaUsers,
      },
      {
        path: AppRoutes.ORGANISATION_ADD_NEW_USER,
        component: InviteUser,
      },
      {
        path: AppRoutes.ORGANISATION_USER_PROFILE,
        component: UserProfile,
      },
    ],
  },
  {
    routePath: '/settings/:path?',
    routes: [
      {
        path: AppRoutes.GENERAL_SETTINGS,
        component: Settings,
      },
      {
        path: AppRoutes.ORGANISATION_LOCATIONS,
        component: OrgLocation,
      },
      {
        path: AppRoutes.DEVICE_SETTINGS,
        component: DeviceSettings
      },
      {
        path: AppRoutes.CREATE_DEVICE,
        component: CreateDevice
      },
      {
        path: AppRoutes.DEVICE_INFO,
        component: DeviceInfo
      },
      {
        path: AppRoutes.USER_LIST,
        component: UserList,
      },
      {
        path: AppRoutes.ADD_NEW_USER,
        component: InviteUser,
      },
      {
        path: AppRoutes.USER_PROFILE,
        component: UserProfile,
      },
      {
        path: AppRoutes.ROLES_LIST,
        component: RolesList,
      },
      {
        path: AppRoutes.ROLES_DETAILS,
        component: RolesDetails,
      },
      {
        path: AppRoutes.ADD_ROLES,
        component: CreateRole,
      },
      {
        path: AppRoutes.AUDIT_LOGS,
        component: AuditListContainer,
      },
      {
        path:AppRoutes.ALERT_CONFIG_SETTINGS,
        component:AlertConfigSettings,
      },
      {
        path:AppRoutes.LOGOUT,
        component:Logout,
      }
    ],
  },
  {
    routePath: '/accountSettings/:path?',
    routes: [
      {
        path: AppRoutes.ACCOUNT_SETTINGS_ACCOUNT,
        component: AccountSettings,
      },
      {
        path: AppRoutes.ACCOUNT_SETTINGS_GENERAL,
        component: GeneralSettings,
      },
      {
        path: AppRoutes.ACCOUNT_SETTINGS_NOTIFICATIONS,
        component: NotificationsSettings,
      },
    ],
  },
  {
    routePath: '/alert/:path?',
    routes: [
      {
        path: AppRoutes.ALERT_CENTRE,
        component: AlertCentre,
      },
    ],
  },
  {
    routePath: '/insights/:path?',
    routes: [
      {
        path: AppRoutes.INSIGHTS,
        component: Insights,
      },
      {
        path: AppRoutes.MY_INSIGHTS,
        component: MyInsights,
      },
      {
        path: AppRoutes.MY_SAVED_INSIGHT,
        component: Insights,
      },
      {
        path: AppRoutes.MY_SAVED_INSIGHT_EDIT,
        component: Insights,
      },
    ],
  },
  {
    routePath: '/devices/:path?',
    routes: [
      {
        path: AppRoutes.DEVICE_HEALTH,
        component: DeviceHealth,
      },
      {
        path: AppRoutes.DEVICE_COMPONENTS,
        component: DeviceComponents,
      },
      {
        path: AppRoutes.DEVICE_CONFIGURATION,
        component: DeviceConfiguration,
      },
      {
        path: AppRoutes.SORTING_LAYOUT,
        component: SortingLayout,
      }
    ],
  },
  {
    routePath: '/workItems/:path?',
    routes: [
      {
        path: AppRoutes.WORK_ITEMS,
        component: WorkItems,
      },
      {
        path: AppRoutes.MY_WORK_ITEMS,
        component: MyWorkitems
      }
    ]
  }
]

export const DeviceManagementRoutes = [
  {
    routePath: '/device/devices',
    routes: [
      {
        path: AppRoutes.DEVICE_LIST,
        component: DeviceList,
      },
    ],
  },
]

export const EventLogOutputRoute = [
  {
    routePath: '/eventLogs/:path?',
    routes: [
      {
        path: AppRoutes.EVENT_LOGS_OUTPUT,
        component: EventOutput,
      },
    ],
  },
]
